<template>
<div>
    <div v-if="me">
        <h3>Actualizaciones para Cyberpuerta</h3>

        <!-- <button class="btn btn-danger btn-lg"><a target="_blank" href="https://sistema.rapoo.mx/wscyberpuerta/jsoncrud.php" style="text-decoration:none"><h4>1- ACTUALIZAR WS INTERNO</h4></a></button>
        <button class="btn btn-danger" style="margin-left: 20px;"><a target="_blank" href="https://sistema.rapoo.mx/wscyberpuerta/api.php" style="text-decoration:none"><h4>2- ACTUALIZAR WS CYBERPUERTA</h4></a></button> -->

   </div>

    <div v-if="!me">
    <h3>No has iniciado sesion</h3>
    </div>
</div>
        </template>
        
        
        
        
        <script>
        import {mapGetters} from 'vuex';
        export default {
        
            name: 'ActualizarWS',
            computed: {
                ...mapGetters(['me'])
            }
        }
        </script>