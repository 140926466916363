<template>

    <form @submit.prevent="handleSubmit">
        <h3>Inicio de Sesión</h3>
    
        <div class="form-group">
            <label> Email </label>
            <input type="email" class="form-control" v-model="email" placeholder="Email"/>
        </div>
    
        <div class="form-group">
            <label> Password </label>
            <input type="password" class="form-control" v-model="password" placeholder="Password"/>
        </div>
    
        <button class="btn btn-primary btn-block">Login</button>
    </form>
    
    </template>
    
    
    <script>
    import axios from 'axios'
    
    export default {
        name: 'MyNewLogin',
        data(){

            return{

                email: '',
                password: ''
            }
        },
        methods: {
            async handleSubmit(){
                
                const response = await axios.post('login', {

                    email: this.email,
                    password: this.password
                });

                localStorage.setItem('access_token', response.data.access_token);

                this.$store.dispatch('me', response.data)

                window.location.href = 'https://sistema.diten.mx/MyHome';
                //this.$router.push('/MyHome');
                //console.log(response.data.access_token);
            }
        }
    }
    
    </script>