<template>

    <div id="app">
        







        <v-row  class="list px-3 mx-auto">
    <v-col cols="6">
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar por Orden de Compra" single-line hide-deatils></v-text-field>
    </v-col>
        </v-row>

<v-container>
    <v-row>
    <v-col cols="12">
      <v-data-table 
          :headers="headers"
          :items="todos"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :search="search"
          :items-per-page="20"
          :class="elevation-1">

          <template v-slot:body="{items}">
      <tbody>
        <tr v-for="item in items" :key="item.id">
        <td>{{item.purchase_order_number}}</td>
        <td v-if="item.warehouse == 1">Monterrey</td>
        <td v-else>CDMX</td>
        <!-- <td >{{item.warehouse}}</td> -->
        <td>{{item.sku}}</td>
        <td>{{item.quantity}}</td>
        <td>{{item.subtotal}}</td>
        <td>{{item.total}}</td>
        <td>{{item.currency}}</td>
        <td>{{ new Date(item.created_at).toLocaleString() }}</td>
        </tr>
        </tbody>
    </template>


        <template v-slot:[`item.actions`]="{ item }">
>
            <v-icon hidden small @click="DeleteApi(item.id)">mdi-delete</v-icon>
            <v-icon hidden @click="urlfile(item.file)">mdi-file</v-icon>

            <!-- <v-icon small @click="formEditar(item.id, item.sku, item.manufacturer_sku, item.manufacturer, item.title, item.description, item.currency, item.price, item.stock)">mdi-pencil</v-icon> -->


        </template>

      </v-data-table>
    </v-col>

    <v-dialog v-model="dialogdetail">
                </v-dialog>


  </v-row>


</v-container>
  
    </div>
  
  </template>

  
  
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.5.7/vuetify.min.js" integrity="sha512-BPXn+V2iK/Zu6fOm3WiAdC1pv9uneSxCCFsJHg8Cs3PEq6BGRpWgXL+EkVylCnl8FpJNNNqvY+yTMQRi4JIfZA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.21.1/axios.min.js" integrity="sha512-bZS47S7sPOxkjU/4Bt0zrhEtWx0y0CRkhEp8IckzK+ltifIIE9EMIMTuT/mEzoIMewUINruDBIR/jJnbguonqQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="//cdn.jsdelivr.net/npm/sweetalert2@11"></script>


  <script>
  
  import axios from "axios";
  import {mapGetters} from 'vuex';
  import TutorialDataService from "../services/TutorialDataService";

  
  const baseUrl = "https://api.diten.mx/api/orders";
  const baseUrladd = "https://api.diten.mx/api/orders/waybills/";
  const baseUrlupdate = "https://api.diten.mx/api/updateproducto/";
  const baseUrldelete = "https://api.diten.mx/api/orders/waybills/get/";
  
  export default {
  
    name: "App",

    computed: {
            ...mapGetters(['me'])
        },
  
        data(){
  
  return{

    todos:[],

    //nameis:'',
    id: null,
    purchase_order_number: '',
    warehouse: '',
    sku: '',
    quantity: '',
    subtotal: '',
    total: '',
    currency: '',
    order_number: '',
    sortBy: 'created_at',
    sortDesc: true,


    search: "",

    headers: [
        // { text: "id", align: "start", sortable: false, value: "id" },
        { text: "Orden de Compra", align: "start", sortable: false, value: "purchase_order_number" },
        { text: "Almacen", align: "start", sortable: false, value: "warehouse" },
        { text: "SKU", align: "start", sortable: false, value: "sku" },
        { text: "Cantidad", align: "start", sortable: true, value: "quantity" },
        { text: "Subtotal", align: "start", sortable: true, value: "subtotal" },
        { text: "Total (Con IVA)", align: "start", sortable: true, value: "total" },
        { text: "Moneda", align: "start", sortable: false, value: "currency" },
        { text: "Fecha de Entrada", align: "start", sortable: true, value: "created_at"},
        // { text: "Orden Interna", align: "start", sortable: false, value: "order_number" },
        // { text: "Archivo", align: "start", sortable: false, value: "file" },
        //{ text: "Archivo", value: "actions", sortable: false },

      ],
      item:[],
      detailedit:{},
      dialogdetail:false

      
  }

},

methods: {

    urlfile(file){
        window.open(`https://api.diten.mx/${file}`);
    },

  async GetApi() {

    await axios

      .get(baseUrl)

      .then((resp) => {

        this.todos = resp.data;

      })

      .catch((err) => {

        console.log(err);

      });

  },

  async PostApi() {

    await axios

      .post(baseUrladd,{order_number:this.order_number, waybills:this.waybills, carrier:this.carrier, file:this.file})

      .then((resp) => {

        console.log(resp);

        //this.nameis='';
        this.order_number='';
        this.waybills='';
        this.carrier='';
        this.file=''

        this.GetApi();

      })

      .catch((err) => {

        console.log(err);

      });

  },

  async DeleteApi(id) {

    await axios

      .get(baseUrldelete+id)

      .then((resp) => {
        
window.open(`http://mysite.test/api/getdload/${id}`);
        console.log(resp);

        this.GetApi();

      })

      .catch((err) => {

        console.log(err);

      });

  },

  async PutApi(id,sku, manufacturer_sku, manufacturer, title, description, currency, price, stock) {

    await axios

      .put(baseUrlupdate+id,{sku:sku, manufacturer_sku:manufacturer_sku, manufacturer:manufacturer, title:title, description:description, currency:currency, price:price, stock:stock})

      .then((resp) => {

        console.log(resp);

        this.GetApi();

      })

      .catch((err) => {

        console.log(err);

      });

  }

},

mounted(){

  this.GetApi();

}

};

</script>

  <style>
  .list {
    max-width: 750px;
  }
  </style>
  
  
  
  <!-- <style>
  
  #app {
  
    font-family: Avenir, Helvetica, Arial, sans-serif;
  
    -webkit-font-smoothing: antialiased;
  
    -moz-osx-font-smoothing: grayscale;
  
    color: #2c3e50;
  
    margin-top: 60px;
  
  }
  
  </style>
   -->
  